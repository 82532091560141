<template>
  <Header v-bind:selected='true' v-bind:account={account} />
  <router-view v-bind:account={account} />
  <div id="fb-root"></div>
</template>

<script>

import Api from './scripts/api';
import Header from '../src/components/global/Header';

export default {
    components: {
      Header,
    },
    data() {
      return {
        account: undefined
      }
    },
    watch: {
      $route (to) {
        if(this.account && this.account.dobSet === false && to != '/birthday-request') {
          this.$router.push('/birthday-request');
        }
      }
    },
    async created() {
      var result = await Api.account();
      if(result.status == 200) {
        this.account = await result.json();
      
        if(this.account && this.account.dobSet === false) {
          this.$router.push('/birthday-request');
        }
      }
      this.attemptCompletePayment();
    },
    methods: {
      setAccount: function(account) {
        this.account = account;
      },
      attemptCompletePayment: async function() {
        var paymentId = this.$route.query.paymentId;
        var payerId = this.$route.query.PayerID;
        if(!paymentId || !payerId) return;

        var result = await Api.paypalCompletePayment(paymentId, payerId);
        if(result.status == 200) {
          this.$toast.success('Paypal topup completed.')
        } else {
          this.$toast.error('Paypal topup failed.')
        }
      }
    },
    mounted() {
        window.fbAsyncInit = function() {
          window.FB.init({
            xfbml            : true,
            version          : 'v10.0'
          });
        };

        (function(d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s); js.id = id;
        js.src = 'https://connect.facebook.net/en_GB/sdk/xfbml.customerchat.js';
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));
    }
}
</script>