import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

var routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import('../views/Logout.vue')
  },
  {
    path: '/signup',
    name: 'Signup',
    component: () => import('../views/Signup.vue')
  },
  {
    path: '/dashboard',
    name: 'Dashboard Active',
    component: () => import('../views/Dashboard/DashboardActive.vue')
  },
  {
    path: '/dashboard/ended',
    name: 'Dashboard Ended',
    component: () => import('../views/Dashboard/DashboardEnded.vue')
  },
  {
    path: '/dashboard/topup',
    name: 'Dashboard Top-Up',
    component: () => import('../views/Dashboard/DashboardTopUp.vue')
  },
  {
    path: '/dashboard/winnings',
    name: 'Dashboard Winnings',
    component: () => import('../views/Dashboard/DashboardWinnings.vue')
  },
  {
    path: '/dashboard/transactions',
    name: 'Transactions',
    component: () => import('../views/Dashboard/DashboardTransactions.vue')
  },
  {
    path: '/dashboard/update-details',
    name: 'Update Details',
    component: () => import('../views/Dashboard/DashboardDetails.vue')
  },
  {
    path: '/dashboard/change-password',
    name: 'Change Password',
    component: () => import('../views/Dashboard/DashboardPassword.vue')
  },
  {
    path: '/forgot-password',
    name: 'Forgot Password',
    component: () => import('../views/ForgotPassword.vue')
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('../views/Profile.vue')
  },
  {
    path: '/competition/:id',
    name: 'Competition',
    component: () => import('../views/Competition.vue')
  },
  {
    path: '/admin',
    name: 'Admin Dashboard Active',
    component: () => import('../views/Admin/AdminActive.vue')
  },
  {
    path: '/admin/add-listing',
    name: 'Admin Add listing',
    component: () => import('../views/Admin/AdminListing.vue')
  },
  {
    path: '/admin/edit-listing/:id',
    name: 'Admin Edit listing',
    component: () => import('../views/Admin/AdminListingEdit.vue')
  },
  {
    path: '/admin/add-winner',
    name: 'Admin Add Winner',
    component: () => import('../views/Admin/AdminAddWinnner.vue')
  },
  {
    path: '/admin/edit-winner/:id',
    name: 'Admin Edit Winner',
    component: () => import('../views/Admin/AdminEditWinnner.vue')
  },
  {
    path: '/admin/ended',
    name: 'Admin Dashboard Ended',
    component: () => import('../views/Admin/AdminEnded.vue')
  },
  {
    path: '/admin/transactions',
    name: 'User Top-Up Transactions',
    component: () => import('../views/Admin/AdminTransactions.vue')
  },
  {
    path: '/admin/update-details',
    name: 'Admin Dashboard - Update Details',
    component: () => import('../views/Admin/AdminDetails.vue')
  },
  {
    path: '/admin/change-password',
    name: 'Admin Dashboard - Update Password',
    component: () => import('../views/Admin/AdminPassword.vue')
  },
  {
    path: '/admin/view-tickets/:id',
    name: 'Admin Dashboard - View Tickets',
    component: () => import('../views/Admin/AdminTickets.vue')
  },
  {
    path: '/admin/users/',
    name: 'Admin Dashboard - Users',
    component: () => import('../views/Admin/AdminUsers.vue')
  },
  {
    path: '/competitions',
    name: 'Active Competitions',
    component: () => import('../views/ActiveCompetitions.vue')
  },
  {
    path: '/giveaways',
    name: 'Giveaways',
    component: () => import('../views/Giveaways.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/live-draws',
    name: 'Live Draws',
    component: () => import('../views/LiveDraws.vue')
  },
  {
    path: '/winners',
    name: 'Winners',
    component: () => import('../views/Winners.vue')
  },
  {
    path: '/contactus',
    name: 'Contact Us',
    component: () => import('../views/ContactUs.vue')
  },
  {
    path: '/password-reset',
    name: 'Reset Password',
    component: () => import('../views/ResetPassword.vue')
  },
  {
    path: '/terms-and-conditions',
    name: 'Terms & Conditions',
    component: () => import('../views/Terms.vue')
  },
  {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    component: () => import('../views/PrivacyPolicy.vue')
  },
  {
    path: '/terms-of-use',
    name: 'Website Terms Of Use',
    component: () => import('../views/TermsOfUse.vue')
  },
  {
    path: '/acceptable-use-policy',
    name: 'Acceptable Use Policy',
    component: () => import('../views/AcceptableUsePolicy.vue')
  },
  {
    path: '/faq',
    name: 'FAQs',
    component: () => import('../views/Faq.vue')
  },
  {
    path: '/birthday-request',
    name: 'Birthday request',
    component: () => import('../views/BirthdayRequest.vue')
  },
  {
    path: '/404',
    name: '404 Page Not Found',
    component: () => import('../views/404.vue')
  }
]

var router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return { el: to.hash }
    }
  },
})


router.beforeEach((to, from, next) => {
  document.title = 'Competition Mania - ' + to.name;
  next();
})

export default router
