module.exports = {
    // url: function() { return 'http://127.0.0.1:3080/api' },
    url: function() { return 'https://competitionmania.co.uk/api' },
    get: async function(endpoint) {
        var response = await fetch(`${module.exports.url()}/${endpoint}`, {
            method: 'GET',
            cache: 'no-cache',
            credentials: 'include',
        });
        return response;
    },
    post: async function(endpoint, data) {
        var response = await fetch(`${module.exports.url()}/${endpoint}`, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return response;
    },
    postFile: async function(endpoint, data) {
        var response = await fetch(`${module.exports.url()}/${endpoint}`, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'include',
            body: data
        });
        return response;    
    },

    mailchimpSignup: async function(email, firstName, lastName) {
        var details = {
            EMAIL: email,
            FNAME: firstName,
            LNAME: lastName
        };
    
        var formBody = [];
        for (var property in details) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(details[property]);
        formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        
        return fetch('https://competitionmania.us1.list-manage.com/subscribe/post?u=c796a9fd7b6289035680b3402&amp;id=6c61e33826', {
            method: 'POST',
            mode: 'no-cors',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: formBody
        });
    },

    account: async function() {
        return this.get('account');
    },

    login: async function(email, password) {
        return this.post('account/login', {
            email: email,
            password: password,
            captcha: 'test'
        });
    },
    logout: async function() {
        return this.post('account/logout', {});
    },
    register: function(email, password, fName, lName, username, dob, phoneNumber, addressFirstLine, addressSecondLine, addressCity, addressCounty, addressPostCode) {
        return this.post('account/register', {
            email: email,
            password: password,
            firstName: fName,
            lastName: lName,
            username: username,
            dob: dob,
            phoneNumber: phoneNumber,
            address: {
                firstLine: addressFirstLine,
                secondLine: addressSecondLine,
                city: addressCity,
                county: addressCounty,
                postCode: addressPostCode
            }
        });
    },
    setAdditionalData: function(username, dob) {
        return this.post('account/additional-info/set', {
            username: username, 
            dob: dob
        });
    },

    resetPassword: function(email) {
        return this.post('account/password/reset/request', {
            email: email
        });
    },
    setPassword: function(password, hash) {
        return this.post('account/password/reset/complete', {
            id: hash,
            password: password
        });
    },
    changePassword: function(oldPassword, newPassword) {
        return this.post('account/password/set', {
            oldPassword: oldPassword,
            newPassword: newPassword
        });
    },
    changeDetails: function(firstName, lastName, city, postCode, firstLine, secondLine, phoneNumber) {
        return this.post('account/details/set', {
            firstName: firstName,
            lastName: lastName,
            city: city,
            postCode: postCode,
            firstLine: firstLine,
            secondLine: secondLine,
            phoneNumber: phoneNumber
        });
    },

    purchase: function(amount, method) {
        return this.post('billing/balance/add', {
            amount: parseFloat(amount),
            method: parseInt(method)
        });
    },
    paypalCompletePayment: function(paymentId, payerId) {
        return this.post('billing/paypal/execute', {
            paymentId: paymentId,
            payerId: payerId
        });
    },


    winnersGet: function() {
        return module.exports.get('winner');
    },
    winnerGetById: function(id) {
        return module.exports.get('winner/' + id);
    },

    contact: function(name, email, message) {
        return module.exports.post('contact', {name: name, email: email, message: message})
    },

    competition: {
        listAll: async function() {
            return module.exports.get('competition/list');
        },
        listLive: async function() {
            return module.exports.get('competition/list/live');
        },
        listActive: async function() {
            return module.exports.get('competition/list/active');    
        },
        listExpired: async function() {
            return module.exports.get('competition/list/expired');    
        },
        listUserActive: async function() {
            return module.exports.get('competition/list/user/active');    
        },
        listUserExpired: async function() {
            return module.exports.get('competition/list/user/expired');    
        },
        listUserWon: async function() {
            return module.exports.get('competition/list/user/won');    
        },
        getById: async function(id) {
            return module.exports.get(`competition/get/${id}`);        
        },
        buyTickets: async function(id, tickets, answer) {
            return module.exports.post(`competition/join`, {
                id: id,
                tickets: tickets,
                answer
            });
        },
        listGiveaways: async function() {
            return module.exports.get('competition/list/active/giveaways');
        }
    },

    admin: {
        createCompetition: async function(title, ticketAmount, startDate, startTime, endDate, endTime, costPerTicket, description, file, livestreamLink, question, answers, correctAnswer, maxAllowedTickets) {
            var data = new FormData();
            data.append('Title', title);
            data.append('Description', description);
            data.append('FeaturedImage', file);
            data.append('StartDate', new Date(startDate).toISOString());
            data.append('StartTime', startTime);
            data.append('EndingData', new Date(endDate).toISOString());
            data.append('EndTime', endTime);
            data.append('TicketPrice', costPerTicket);
            data.append('MaxTickets', ticketAmount);
            data.append('LivestreamLink', livestreamLink);
            data.append('Question', question);
            data.append('Answers', answers);
            data.append('Answer', correctAnswer);
            data.append('PersonMinTickets', 1);
            data.append('PersonMaxTickets', parseInt(maxAllowedTickets));

            return module.exports.postFile('admin/competition/create', data);
        },
        editCompetition: async function(id, title, ticketAmount, startDate, startTime, endDate, endTime, costPerTicket, description, file, livestreamLink, question, answers, correctAnswer, maxAllowedTickets) {
            var data = new FormData();
            data.append('Title', title);
            data.append('Description', description);
            data.append('FeaturedImage', file);
            data.append('StartDate', new Date(startDate).toISOString());
            data.append('StartTime', startTime);
            data.append('EndingData', new Date(endDate).toISOString());
            data.append('EndTime', endTime); 
            data.append('TicketPrice', costPerTicket);
            data.append('MaxTickets', ticketAmount);
            data.append('LivestreamLink', livestreamLink);
            data.append('Question', question);
            data.append('Answers', answers);
            data.append('Answer', correctAnswer);
            data.append('PersonMinTickets', 1);
            data.append('PersonMaxTickets', parseInt(maxAllowedTickets));

            return module.exports.postFile('admin/competition/update/' + id, data);
        },
        luckyDipuser: async function(id, userEmail) {
            return module.exports.post(`admin/competition/tickets/${id}/lucky-dip`, {
                userEmail: userEmail
            })
        },
        getTickets: async function(id) {
            return module.exports.get('admin/competition/tickets/' + id);
        },
        winnerAdd: async function(name, ticket, description, file, date, link) {
            var data = new FormData();
            data.append('Name', name);
            data.append('Description', description);
            data.append('Image', file);
            data.append('Date', new Date(date).toISOString());
            data.append('WinningTicket', parseInt(ticket));
            data.append('Link', link)

            return module.exports.postFile('admin/winner/add', data);
        },
        winnerEdit: async function(id, name, ticket, description, file, date, link) {
            var data = new FormData();
            data.append('Name', name);
            data.append('Description', description);
            data.append('Image', file);
            data.append('Date', new Date(date).toISOString());
            data.append('WinningTicket', parseInt(ticket));
            data.append('Link', link);

            return module.exports.postFile(`admin/winner/${id}/edit`, data);
        },
        setCompetitionWinner: async function(competitionId, winningNumber) {
            return module.exports.post('admin/competition/winner/set', {
                competition: competitionId,
                ticket: winningNumber
            })
        },
        isAdmin: async function() {
            return module.exports.get('admin');
        },

        usersGetAll: async function() {
            return module.exports.get('admin/users');
        },
        usersSearch: async function(term) {
            return module.exports.get('admin/users/search/' + term);
        },
        usersBan: async function(user) {
            return module.exports.post(`admin/users/${user}/actions/ban`, { })
        },
        usersUnban: async function(user) {
            return module.exports.post(`admin/users/${user}/actions/unban`, { })
        },
        usersConfirm: async function(user) {
            return module.exports.post(`admin/users/${user}/actions/confirm`, { })
        },
        usersPasswordSet: async function(user, password) {
            return module.exports.post(`admin/users/${user}/actions/password/set`, { password: password })
        },
        usersBalanceSet: async function(user, balance) {
            return module.exports.post(`admin/users/${user}/actions/balance/set`, { balance: balance })
        },
        usersBalanceAdd: async function(user, balance) {
            return module.exports.post(`admin/users/${user}/actions/balance/add`, { balance: balance })
        },
        ordersGetAll: async function() {
            return module.exports.get(`admin/billing/transactions`);
        }
    }
 }