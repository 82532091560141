<template>

<div class="col-xs-6 col-sm-12 col-md-6" v-if="this.listing !== undefined || null">
    <a v-if="this.listing.hasEnded" target="_blank" v-bind:href="'https://' + this.listing.livestreamLink">

          <div class="row text-center" v-if="this.account && this.account.account && this.account.account.isAdmin">
            <div class="col">
              <router-link v-bind:to="'/admin/edit-listing/' + this.listing.id"><button type="button" class="btn btn-custom mt-3" style="background-color:#239486;">Edit Competition</button></router-link>
            </div>
            <div class="col">
              <router-link v-bind:to="'/admin/view-tickets/' + this.listing.id"><button type="button" class="btn btn-custom mt-3" style="background-color:#239486;">View Tickets</button></router-link>
            </div>
            <div class="col">
              <button type="button" class="btn btn-custom mt-3" v-on:click="luckyDipUser()" style="background-color:#239486;">Lucky Dip User</button>
            </div>
          </div> 

          <div id="competition-item">
              <h1 class="competition-title">{{this.listing.title}}</h1>
              <img v-bind:src="'data:image/jpeg;base64,'+this.listing.featuredImage" alt=""/>
          </div>  
          
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" height="70" viewBox="0 0 1225 152">
            <defs>
              <filter id="Rectangle_196" x="0" y="0" width="1225" height="152" filterUnits="userSpaceOnUse">
                <feOffset dy="3" input="SourceAlpha"/>
                <feGaussianBlur stdDeviation="3" result="blur"/>
                <feFlood flood-opacity="0.161"/>
                <feComposite operator="in" in2="blur"/>
                <feComposite in="SourceGraphic"/>
              </filter>
            </defs>
            <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Rectangle_196)">
              <rect id="Rectangle_196-2" data-name="Rectangle 196" width="1207" height="134" rx="67" transform="translate(9 6)" fill="#4fd1c1"/>
            </g>
            <ellipse id="Ellipse_634" data-name="Ellipse 634" cx="55.5" cy="58" rx="55.5" ry="58" transform="translate(18 15)" fill="#fff"/>
            <g id="Icon_ionic-md-time" data-name="Icon ionic-md-time" transform="translate(33.703 33.542)">
              <path id="Path_5" data-name="Path 5" d="M43.564,3.375A40.228,40.228,0,1,0,83.83,43.6,40.213,40.213,0,0,0,43.564,3.375Zm.039,72.41A32.182,32.182,0,1,1,75.785,43.6,32.182,32.182,0,0,1,43.6,75.785Z" transform="translate(-3.375 -3.375)" fill="#4fd1c1"/>
              <path id="Path_6" data-name="Path 6" d="M22.572,10.688H16.537V34.824L37.657,47.492l3.017-4.951-18.1-10.734Z" transform="translate(19.667 9.426)" fill="#4fd1c1"/>
            </g>
            <circle id="Ellipse_635" data-name="Ellipse 635" cx="54" cy="54" r="54" transform="translate(523 23)" fill="#fff"/>
            
            <text v-if="!this.listing.ticketsBoughtByUser || this.listing.ticketsBoughtByUser.length == 0" id="_30_100" data-name="30 / 100" transform="translate(766 91)" fill="#fff" font-size="53" font-family="NirmalaUI, Nirmala UI"><tspan x="-96.27" y="0">{{ticketsLeft}} / {{this.listing.maxTickets}}</tspan></text>
            <text v-else id="_30_100" data-name="30 / 100" transform="translate(766 91)" fill="#fff" font-size="42" font-family="NirmalaUI, Nirmala UI"><tspan x="-96.27" y="0">{{this.listing.ticketsBoughtByUser.toString()}}</tspan></text>

            <path id="Icon_awesome-ticket-alt" data-name="Icon awesome-ticket-alt" d="M15.378,16.033H53.821V39.1H15.378ZM63.432,27.566A5.767,5.767,0,0,0,69.2,33.333V44.866a5.767,5.767,0,0,1-5.767,5.767H5.767A5.767,5.767,0,0,1,0,44.866V33.333a5.767,5.767,0,0,0,5.767-5.767A5.767,5.767,0,0,0,0,21.8V10.267A5.767,5.767,0,0,1,5.767,4.5H63.432A5.767,5.767,0,0,1,69.2,10.267V21.8A5.767,5.767,0,0,0,63.432,27.566ZM57.666,15.072a2.883,2.883,0,0,0-2.883-2.883H14.416a2.883,2.883,0,0,0-2.883,2.883V40.061a2.883,2.883,0,0,0,2.883,2.883H54.782a2.883,2.883,0,0,0,2.883-2.883Z" transform="translate(542.127 49.007)" fill="#4fd1c1"/>
            <text id="_3.50" data-name="3.50" transform="translate(1119 92)" fill="#fff" font-size="51" font-family="NirmalaUI, Nirmala UI"><tspan x="-46.767" y="0">{{this.listing.ticketPrice.toFixed(2)}}</tspan></text>
            <text transform="translate(307 76)" fill="#fff" font-size="54" font-family="NirmalaUI, Nirmala UI"><tspan x="-135.541" y="0">{{timeLeft}}</tspan></text>
            <text id="DAYS" transform="translate(203 115)" fill="#fff" font-size="25" font-family="NirmalaUI-Bold, Nirmala UI" font-weight="700"><tspan x="-31.281" y="0">DAYS</tspan></text>
            <text id="HRS" transform="translate(278 115)" fill="#fff" font-size="25" font-family="NirmalaUI-Bold, Nirmala UI" font-weight="700"><tspan x="-24.744" y="0">HRS</tspan></text>
            <text id="MIN" transform="translate(350 115)" fill="#fff" font-size="25" font-family="NirmalaUI-Bold, Nirmala UI" font-weight="700"><tspan x="-25.8" y="0">MIN</tspan></text>
            <text id="SEC" transform="translate(421 115)" fill="#fff" font-size="25" font-family="NirmalaUI-Bold, Nirmala UI" font-weight="700"><tspan x="-21.46" y="0">SEC</tspan></text>
            <circle id="Ellipse_637" data-name="Ellipse 637" cx="58" cy="58" r="58" transform="translate(925 15)" fill="#fff"/>
            <path id="Icon_metro-gbp" data-name="Icon metro-gbp" d="M45.506,40.338V53.407a1.1,1.1,0,0,1-1.14,1.14H10.323a1.1,1.1,0,0,1-1.14-1.14V48.065a1.156,1.156,0,0,1,1.14-1.14h3.454V33.287H10.394a1.079,1.079,0,0,1-.819-.338,1.126,1.126,0,0,1-.321-.8V27.482a1.1,1.1,0,0,1,1.14-1.14h3.383V18.4a12.91,12.91,0,0,1,4.4-10.042,16.12,16.12,0,0,1,11.2-3.953A18.171,18.171,0,0,1,41.3,8.858a1.051,1.051,0,0,1,.356.73,1.1,1.1,0,0,1-.249.8l-3.668,4.522a1.092,1.092,0,0,1-.783.427.954.954,0,0,1-.819-.249,7.824,7.824,0,0,0-.926-.677,12.966,12.966,0,0,0-2.457-1.14,9.408,9.408,0,0,0-3.312-.641,7,7,0,0,0-4.879,1.674,5.625,5.625,0,0,0-1.852,4.38v7.656H33.576a1.126,1.126,0,0,1,.8.321,1.079,1.079,0,0,1,.338.819v4.665a1.156,1.156,0,0,1-1.14,1.14H22.715v13.5H37.458V40.338a1.126,1.126,0,0,1,.321-.8A1.079,1.079,0,0,1,38.6,39.2h5.769a1.079,1.079,0,0,1,.819.338,1.126,1.126,0,0,1,.32.8Z" transform="translate(956.511 44.116)" fill="#4fd1c1"/>
          </svg>
    </a>

    <router-link v-else v-bind:to="'/competition/' + this.listing.id">

          <div class="row text-center" v-if="this.account && this.account.account && this.account.account.isAdmin">
            <div class="col">
              <router-link v-bind:to="'/admin/edit-listing/' + this.listing.id"><button type="button" class="btn btn-custom mt-3" style="background-color:#239486;">Edit Competition</button></router-link>
            </div>
            <div class="col">
              <router-link v-bind:to="'/admin/view-tickets/' + this.listing.id"><button type="button" class="btn btn-custom mt-3" style="background-color:#239486;">View Tickets</button></router-link>
            </div>
            <div class="col">
              <button type="button" class="btn btn-custom mt-3" v-on:click="luckyDipUser()" style="background-color:#239486;">Lucky Dip User</button>
            </div>
          </div> 

          <div id="competition-item">
              <h1 class="competition-title">{{this.listing.title}}</h1>
              <img v-bind:src="'data:image/jpeg;base64,'+this.listing.featuredImage" alt=""/>
          </div>  

          <p style="text-align:center;" v-if="!this.listing.ticketsBoughtByUser || this.listing.ticketsBoughtByUser.length == 0">My Entry Ticket(s): No Entries</p>
          <p v-else style="text-align:center;">My Entry Ticket(s): {{this.listing.ticketsBoughtByUser.toString()}}</p>
          
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" height="70" viewBox="0 0 1225 152">
            <defs>
              <filter id="Rectangle_196" x="0" y="0" width="1225" height="152" filterUnits="userSpaceOnUse">
                <feOffset dy="3" input="SourceAlpha"/>
                <feGaussianBlur stdDeviation="3" result="blur"/>
                <feFlood flood-opacity="0.161"/>
                <feComposite operator="in" in2="blur"/>
                <feComposite in="SourceGraphic"/>
              </filter>
            </defs>
            <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Rectangle_196)">
              <rect id="Rectangle_196-2" data-name="Rectangle 196" width="1207" height="134" rx="67" transform="translate(9 6)" fill="#4fd1c1"/>
            </g>
            <ellipse id="Ellipse_634" data-name="Ellipse 634" cx="55.5" cy="58" rx="55.5" ry="58" transform="translate(18 15)" fill="#fff"/>
            <g id="Icon_ionic-md-time" data-name="Icon ionic-md-time" transform="translate(33.703 33.542)">
              <path id="Path_5" data-name="Path 5" d="M43.564,3.375A40.228,40.228,0,1,0,83.83,43.6,40.213,40.213,0,0,0,43.564,3.375Zm.039,72.41A32.182,32.182,0,1,1,75.785,43.6,32.182,32.182,0,0,1,43.6,75.785Z" transform="translate(-3.375 -3.375)" fill="#4fd1c1"/>
              <path id="Path_6" data-name="Path 6" d="M22.572,10.688H16.537V34.824L37.657,47.492l3.017-4.951-18.1-10.734Z" transform="translate(19.667 9.426)" fill="#4fd1c1"/>
            </g>
            <circle id="Ellipse_635" data-name="Ellipse 635" cx="54" cy="54" r="54" transform="translate(523 23)" fill="#fff"/>
            
            <!-- v-if="!this.listing.ticketsBoughtByUser || this.listing.ticketsBoughtByUser.length == 0" -->
            <text id="_30_100" data-name="30 / 100" transform="translate(766 91)" fill="#fff" font-size="53" font-family="NirmalaUI, Nirmala UI"><tspan x="-96.27" y="0">{{ticketsLeft}} / {{this.listing.maxTickets}}</tspan></text>
            <!-- <text v-else id="_30_100" data-name="30 / 100" transform="translate(766 91)" fill="#fff" font-size="42" font-family="NirmalaUI, Nirmala UI"><tspan x="-96.27" y="0">{{this.listing.ticketsBoughtByUser.toString()}}</tspan></text> -->

            <path id="Icon_awesome-ticket-alt" data-name="Icon awesome-ticket-alt" d="M15.378,16.033H53.821V39.1H15.378ZM63.432,27.566A5.767,5.767,0,0,0,69.2,33.333V44.866a5.767,5.767,0,0,1-5.767,5.767H5.767A5.767,5.767,0,0,1,0,44.866V33.333a5.767,5.767,0,0,0,5.767-5.767A5.767,5.767,0,0,0,0,21.8V10.267A5.767,5.767,0,0,1,5.767,4.5H63.432A5.767,5.767,0,0,1,69.2,10.267V21.8A5.767,5.767,0,0,0,63.432,27.566ZM57.666,15.072a2.883,2.883,0,0,0-2.883-2.883H14.416a2.883,2.883,0,0,0-2.883,2.883V40.061a2.883,2.883,0,0,0,2.883,2.883H54.782a2.883,2.883,0,0,0,2.883-2.883Z" transform="translate(542.127 49.007)" fill="#4fd1c1"/>
            <text id="_3.50" data-name="3.50" transform="translate(1119 92)" fill="#fff" font-size="51" font-family="NirmalaUI, Nirmala UI"><tspan x="-46.767" y="0">{{this.listing.ticketPrice.toFixed(2)}}</tspan></text>
            <text transform="translate(307 76)" fill="#fff" font-size="54" font-family="NirmalaUI, Nirmala UI"><tspan x="-135.541" y="0">{{timeLeft}}</tspan></text>
            <text id="DAYS" transform="translate(203 115)" fill="#fff" font-size="25" font-family="NirmalaUI-Bold, Nirmala UI" font-weight="700"><tspan x="-31.281" y="0">DAYS</tspan></text>
            <text id="HRS" transform="translate(278 115)" fill="#fff" font-size="25" font-family="NirmalaUI-Bold, Nirmala UI" font-weight="700"><tspan x="-24.744" y="0">HRS</tspan></text>
            <text id="MIN" transform="translate(350 115)" fill="#fff" font-size="25" font-family="NirmalaUI-Bold, Nirmala UI" font-weight="700"><tspan x="-25.8" y="0">MIN</tspan></text>
            <text id="SEC" transform="translate(421 115)" fill="#fff" font-size="25" font-family="NirmalaUI-Bold, Nirmala UI" font-weight="700"><tspan x="-21.46" y="0">SEC</tspan></text>
            <circle id="Ellipse_637" data-name="Ellipse 637" cx="58" cy="58" r="58" transform="translate(925 15)" fill="#fff"/>
            <path id="Icon_metro-gbp" data-name="Icon metro-gbp" d="M45.506,40.338V53.407a1.1,1.1,0,0,1-1.14,1.14H10.323a1.1,1.1,0,0,1-1.14-1.14V48.065a1.156,1.156,0,0,1,1.14-1.14h3.454V33.287H10.394a1.079,1.079,0,0,1-.819-.338,1.126,1.126,0,0,1-.321-.8V27.482a1.1,1.1,0,0,1,1.14-1.14h3.383V18.4a12.91,12.91,0,0,1,4.4-10.042,16.12,16.12,0,0,1,11.2-3.953A18.171,18.171,0,0,1,41.3,8.858a1.051,1.051,0,0,1,.356.73,1.1,1.1,0,0,1-.249.8l-3.668,4.522a1.092,1.092,0,0,1-.783.427.954.954,0,0,1-.819-.249,7.824,7.824,0,0,0-.926-.677,12.966,12.966,0,0,0-2.457-1.14,9.408,9.408,0,0,0-3.312-.641,7,7,0,0,0-4.879,1.674,5.625,5.625,0,0,0-1.852,4.38v7.656H33.576a1.126,1.126,0,0,1,.8.321,1.079,1.079,0,0,1,.338.819v4.665a1.156,1.156,0,0,1-1.14,1.14H22.715v13.5H37.458V40.338a1.126,1.126,0,0,1,.321-.8A1.079,1.079,0,0,1,38.6,39.2h5.769a1.079,1.079,0,0,1,.819.338,1.126,1.126,0,0,1,.32.8Z" transform="translate(956.511 44.116)" fill="#4fd1c1"/>
          </svg>
    </router-link>


</div>

<div v-else>
  Loading!
</div>

</template>


<script>
import Api from '../../scripts/api';

export default {
    props: {
        listing : Object,
        account: Object,
    },
    data() {
      return {
        now: Date.now()
      }
    },
    created() {
      var self = this
      setInterval(function() {
        self.now = Date.now();
      }, 1000);
    },
    methods: {
        async luckyDipUser() {
          var userEmail = prompt('What is the user\'s email address?');
          if(!userEmail) return;

          var result = await Api.admin.luckyDipuser(this.listing.id, userEmail);
          if(result.status == 404) return this.$toast.warning('Could not find user with provided email.');
          if(result.status == 410) return this.$toast.warning('The specified competition has already ended.');

          if(result.status == 200) {
            alert('Lucky dipped user with number ' + (await result.json()).number);
          }
        }
    },
    computed: {
        ticketsLeft: function() {
            var left = this.listing.maxTickets;
            for(var entry in this.listing.entries) {
                if(this.listing.entries[entry].bought) {
                    left--;
                }
            }
            return left;
        },
        timeLeft: function() {

          var today = new Date();
          
          today.setHours(today.getHours());

          if(this.listing.hasEnded) return 'Watch Live';

          this.now.toString(); // force update
          var endDate = this.listing.endDate.split("Z")[0];


          function getTimeRemaining(endtime) {
            var total = Date.parse(endtime) - Date.parse(today);

            var seconds = Math.floor((total / 1000) % 60);
            var minutes = Math.floor((total / 1000 / 60) % 60);
            var hours = Math.floor((total / (1000 * 60 * 60)) % 24);
            var days = Math.floor(total / (1000 * 60 * 60 * 24));
                        

            return {
              total,
              days,
              hours,
              minutes,
              seconds
            };
          }

          function addZeroIfSingleNumber(number) {
            var num = number.toString();
            if(num.length <= 1) return '0'+ num;
            return num;
          }
          
          var timeRemaining = getTimeRemaining(endDate);

          return addZeroIfSingleNumber(timeRemaining.days) + ' ' + addZeroIfSingleNumber(timeRemaining.hours) + ':' + addZeroIfSingleNumber(timeRemaining.minutes) + ':' + addZeroIfSingleNumber(timeRemaining.seconds); //'09 19:15:15'
        }
    }
}
</script>