<template>

    <div v-if="this.account && this.account.account && this.account.account.email && !this.account.account.email.confirmed" class="notice-message text-center">Please confirm your email!</div>

      <header class="py-3 container">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-6 col-2">
            <router-link class="" v-bind:to="'/'"><img class="logo" v-bind:src="require('../../assets/img/logo.jpg')" alt="Competition Mania"/></router-link>

          </div>
          <div class="col-lg-6 col-md-6 col-sm-6 col-10 d-flex justify-content-end mt-3 mb-3">
            <ul v-if="!this.hasAccount()">
               <router-link v-bind:to="'/login'" class="signup">
                  Sign In
                </router-link>
            </ul>
            <ul v-else>
              <ul class="mt-3 d-xl-block">
                <div class="dropdown">
                  <button class="login dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span style="position: relative; left: -10px;">Dashboard</span>
                  </button>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <router-link class="dropdown-item" v-bind:to="'/dashboard'">My Entries</router-link>
                    <router-link class="dropdown-item" v-bind:to="'/dashboard/update-details'">Update Details</router-link>
                    <!-- <router-link  v-bind:to="'/logout'">Logout</router-link> -->
                    <a class="dropdown-item" href="/logout">Logout</a>
                  </div>
                  <router-link v-bind:to="'/dashboard/topup'" class="balance">£{{this.account.account.wallet.balance > 100 ? this.account.account.wallet.balance : this.account.account.wallet.balance.toFixed(2)}}</router-link>
<!-- {{this.listing.ticketPrice.toFixed(2)}} -->
                </div>
              </ul>
            </ul>

          </div>
        </div>
      </header>

      <div class="nav-scroller py-1 mb-4 container">
        <div class="back d-md-none d-lg-none" v-on:click="scrollWin(-300)">
          <svg xmlns="http://www.w3.org/2000/svg" width="11.165" height="17.838" viewBox="0 0 11.165 17.838">
            <path id="Icon_material-keyboard-arrow-left" data-name="Icon material-keyboard-arrow-left" d="M23.165,23.992l-6.9-6.823,6.9-6.823-2.125-2.1L12,17.169l9.041,8.919Z" transform="translate(-12 -8.25)"/>
          </svg>
        </div> 
        <nav class="nav d-flex justify-content-between" id="navScroller">
          <router-link class="p-2" v-bind:class="{'active-page':this.$route.path ==  '/'}" v-bind:to="'/'">Home</router-link>
          <router-link class="p-2" v-bind:class="{'active-page':this.$route.path ==  '/about'}" v-bind:to="'/about'">About Us</router-link>
          <router-link class="p-2" v-bind:class="{'active-page':this.$route.path ==  '/competitions'}" v-bind:to="'/competitions'">Active Competitions</router-link>
          <router-link class="p-2" v-bind:class="{'active-page':this.$route.path ==  '/giveaways'}" v-bind:to="'/giveaways'">Giveways</router-link>
          <router-link class="p-2" v-bind:class="{'active-page':this.$route.path ==  '/live-draws'}" v-bind:to="'/live-draws'">Live Draws</router-link>
          <router-link class="p-2" v-bind:class="{'active-page':this.$route.path ==  '/winners'}" v-bind:to="'/winners'">Winners</router-link>
          <router-link class="p-2" v-bind:class="{'active-page':this.$route.path == '/contactus'}" v-bind:to="'/contactus'">Contact Us</router-link>
        </nav>
        <div class="forward d-md-none d-lg-none" v-on:click="scrollWin(500)">
          <svg xmlns="http://www.w3.org/2000/svg" width="11.165" height="17.838" viewBox="0 0 11.165 17.838">
            <path id="Icon_material-keyboard-arrow-left" data-name="Icon material-keyboard-arrow-left" d="M23.165,23.992l-6.9-6.823,6.9-6.823-2.125-2.1L12,17.169l9.041,8.919Z" transform="translate(23.165 26.088) rotate(180)"/>
          </svg>
        </div> 
      </div>
    

</template>

<script>
export default {
  props: {
    account: Object,
    selected: Boolean
  },
  methods: {
    hasAccount: function() {
      if(!this.account || !this.account.account) return false;
      return this.account.account.id != undefined;
    },
    /* Set the width of the side navigation to 250px and the left margin of the page content to 250px and add a black background color to body */
    openNav: function() {
      document.getElementById("mySidenav").style.width = "250px";
      document.getElementById("main").style.marginLeft = "250px";
      document.body.style.backgroundColor = "rgba(0,0,0,0.4)";
    },

    /* Set the width of the side navigation to 0 and the left margin of the page content to 0, and the background color of body to white */
    closeNav: function() {
      document.getElementById("mySidenav").style.width = "0";
      document.getElementById("main").style.marginLeft = "0";
      document.body.style.backgroundColor = "white";
    },
    scrollWin: function(width) {
        document.getElementById("navScroller").scrollTo(width, 0);
    },

  },
  mounted() {

  },
  beforeUnmount() {
    console.log('unmount');
  }
}


</script>

<style>
  #navScroller {
    display:block;
    width: 100%;
  }
</style>